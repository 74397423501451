import React, { useState, useEffect } from 'react'; // Corrected import
import { Link } from "react-scroll";

function Navbar() {
    const [navActive, setNavActive] = useState(false); // Corrected useState usage

    const toggleNav = () => {
        setNavActive(!navActive);
    }

    const closeMenu = () => {
        setNavActive(false);
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 500) {
                closeMenu(); // Corrected function invocation
            }
        }
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    useEffect(() => {
        if (window.innerWidth <= 1200) {
            closeMenu(); // Corrected function invocation
        }
    }, []);

    return (
        <nav className={`navbar ${navActive ? "active" : ""}`}>
            {/*<div>
                <img src="./img/logo.svg" alt="logoipsum"/>
    </div>*/}
            <a className={`nav__hamburger ${navActive ? "active":""}`} onClick={toggleNav}>
                <span className="nav__hamburger__line"></span>
                <span className="nav__hamburger__line"></span>
                <span className="nav__hamburger__line"></span>
            </a>
            <div className={`navbar--items ${navActive ? "active" : ""}`}>
                <ul>
                    <li>
                        <Link onClick={closeMenu} activeClass="navbar--active--content" spy={true} smooth={true} offset={-70} duration={500} to="heroSection" className="navbar--content">Home</Link>
                    </li>
                    <li>
                        <Link onClick={closeMenu} activeClass="navbar--active--content" spy={true} smooth={true} offset={-70} duration={500} to="MyPortfolio" className="navbar--content">Portofolio</Link>
                    </li>
                    <li>
                        <Link onClick={closeMenu} activeClass="navbar--active--content" spy={true} smooth={true} offset={-70} duration={500} to="AboutMe" className="navbar--content">About Me</Link>
                    </li>
                </ul>
            </div>
            <Link 
            onClick={closeMenu} activeClass="navbar--active--content" spy={true} smooth={true} offset={-70} duration={500} to="contact-me" className="btn btn-outline-primary">Contact Me</Link>
        </nav>
    );
}

export default Navbar;
