export default function AboutMe(){
    return(
        <section id="AboutMe" className="about--section">
            <div className="about--section--img">
                <img src="./img/pfp.jpg" alt="About Me"/>
            </div>
            <div className="hero--section--content--box about--section--box">
    <div className="hero--section--content">
        
        <h1 className="skills--section--heading">About Me</h1>
        <p className="hero--section--description">
            My journey begun in an unremarkable city in Eastern Europe, where the boredom of having to grow up amongst gray commie blocks, and having unrestricted internet access since the age of 6, lead me very early on to my interest in physics and computer science.
        </p>
        <p className="hero--section--description">
            My quest for a more hands on, practical approach to applying the principles of physics led me to the Electrical Engineering master’s program at Ecole Polytechnique de Bruxelles, allowing me to channel my curiosity and ambition into developing innovative solutions that bridge the gap between theoretical physics and real-world applications.
        </p>
        <p className="hero--section--description">
            My journey hasn't been without its challenges. From navigating personal relationships to striving for academic excellence, each experience has taught me more about myself and the kind of person I strive to be. I'm a firm believer in the power of self-reflection and the importance of emotional intelligence, both in personal and professional spheres.
        </p>
        <p className="hero--section--description">
            As I continue on this path, I aim to apply my learning and experiences towards making a positive impact, embracing change, and fostering meaningful connections. I'm always open to new experiences that push me to grow and help others along the way.
        </p>
        <p className="hero--section--description">
            If you happen to be offering job opportunities, reach out to me! I'm on a mission to ensure my next big adventure doesn't involve a dramatic return to Romania and a diet based on McDonald's and instant noodles. In all seriousness,  I’m all in for new challenges that can benefit from my unique blend of skills and passion. So, let's make something amazing together and keep the starvation away XD
        </p>
    </div>
</div>

        </section>

    );
}