import AboutMe from "../AboutMe";
import HeroSection from "../HeroSection";
import MySkills from "../MySkills";
import MyPortofolio from "../MyPortofolio";
import ContactMe from "../ContactMe";

export default function Home() {
  return (
    <>
      <HeroSection />
      <AboutMe/>
      <MySkills/>
      <MyPortofolio/>
      <ContactMe/>
    </>
  );
}