import React from 'react';
import { Link } from 'react-scroll';
export default function HeroSection() {
    return (
        <section id="heroSection" className="hero--section">
        <div className="hero--section--content--box">
            <div className="hero--section--content">
            <p className="section--title">Salut tout le monde</p>
            <h1 className="hero--section--title">
                <span className="hero--section--title--color">Octavian Gurlui</span>{" "}
                <br/>
                Aspiring Engineer
            </h1>
            <p className="hero--section--description">
                I’m Octavian (or Octave, if you happen to have met me in Belgium). <br/>Thank you for visiting 
                my corner of the internet. <br/>
                This website provides an overview of various work experiences,<br/> projects, and related activities 
                that I have done/currently working on.
            </p>
            
            </div>
            
            <div className="get-in-touch-button">
            <Link
                    to="contact-me"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="btn btn-primary">
                    Get in touch
                </Link>
            </div>
            
        </div>
       <div className="hero--section--img">
        <img src="./img/hero_img.png" alt="Hero Section"/>
       </div>
        </section>
    );
}