export default function ContactMe() {
    return (
        <section id="contact-me" className="contact--section">
            <h1 className="skills--section--heading">Reach out to me</h1>
            <div className="contact--options">
                <a href="https://www.instagram.com/octavian.gurlui/" target="_blank" rel="noopener noreferrer">
                    <button className="btn btn-primary">
                        <img src="./img/insta.png" alt="Instagram" style={{ marginRight: '8px', width: '32px', height: '32px' }} />
                        Instagram
                    </button>
                </a>
                <a href="https://www.linkedin.com/in/octavian-gurlui-16bb4b1a2/" target="_blank" rel="noopener noreferrer">
                    <button className="btn btn-primary">
                        <img src="./img/linkedin.png" alt="LinkedIn" style={{ marginRight: '8px', width: '32px', height: '32px' }} />
                        LinkedIn
                    </button>
                </a>
                <a href="mailto:octavian.gurlui@gmail.com">
                    <button className="btn btn-primary">
                        <img src="./img/outlook.png" alt="Email" style={{ marginRight: '8px', width: '32px', height: '32px' }} />
                        Email
                    </button>
                </a>
            </div>
        </section>
    );
}
